import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import Iconify from 'src/components/iconify';

export default function AttributionModal({ open, onClose }) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: 'background.neutral',
          maxWidth: '400px',
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.16)',
        }
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ 
          pt: 2.5,
          pb: 0.5,
          px: 3,
        }}
      >
        <Typography variant="h6">
          Quantum Edge LTD
        </Typography>
        
        <IconButton
          onClick={onClose}
          sx={{
            color: 'text.secondary',
            '&:hover': { 
              color: 'text.primary',
              bgcolor: 'action.hover',
            },
          }}
        >
          <Iconify icon="mingcute:close-line" width={24} />
        </IconButton>
      </Stack>

      <DialogContent sx={{ p: 3, pt: 2 }}>
        <Typography
          variant="body2"
          sx={{ 
            color: 'text.secondary',
            lineHeight: 1.6,
          }}
        >
          This product was developed by Quantum Edge LTD. Quantum Edge LTD is not affiliated with, nor responsible for, the conduct, performance, or practices of any third-party brokers using this software.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

AttributionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}; 