import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, forwardRef } from 'react';

// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// routes
import { RouterLink } from 'src/routes/components';

import { getAPIUrl } from 'src/config';

import { useSettingsContext } from '../settings';
import { usePathname } from 'src/routes/hook';
import { useAuthContext } from 'src/auth/hooks';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const { domainData: domainInfoLocal } = useAuthContext();

  const settings = useSettingsContext();

  const pathname = usePathname();

  const getDomain = (input) => {
    const parts = input?.split('.');
    if (parts?.length >= 2) {
      return parts?.slice(-2).join('.');
    }
    return input;
  };

  const [isValid, setIsValid] = useState(false);

  const checkImageValidity = () => {
    const img = new Image();
    if (settings?.themeMode === 'light') {
      img.src = domainInfoLocal?.internal_brand?.dashboard_white_logo;
    } else {
      img.src = domainInfoLocal?.internal_brand?.dashboard_dark_logo;
    }

    img.onload = () => {
      setIsValid(true);
    };

    img.onerror = () => {
      setIsValid(false);
    };
  };

  useEffect(() => {
    if (domainInfoLocal) {
      checkImageValidity();
    }
  }, [domainInfoLocal, settings?.themeMode]);

  const isLoginOrRegister = useMemo(() => {
    if (['/login', '/register'].includes(pathname)) {
      return true;
    }

    return false;
  }, [pathname]);

  // eslint-disable-next-line consistent-return
  const domainLogo = useMemo(() => {
    if (domainInfoLocal) {
      if (settings?.themeMode === 'light') {
        return domainInfoLocal?.internal_brand?.dashboard_white_logo;
      } 
      if (settings?.themeMode === 'dark') {
        return domainInfoLocal?.internal_brand?.dashboard_dark_logo;
      }
    }
  }, [domainInfoLocal, settings?.themeMode]);

  const logo = (
    <Box
      component="img"
      src={domainLogo ? domainLogo?.includes('http') ? domainLogo : `${getAPIUrl()}${domainLogo}` : ""}
      sx={{ 
        maxWidth: 1, 
        maxHeight: 65, 
        height: 65,
        width: 'fix-content',    
        cursor: 'pointer',
        borderRadius: 0.5,
        overflow: 'hidden',
        flex: 'none',
        objectFit: 'contain',
        ...sx 
      }}
    />
  );

  if (disabledLink && isValid) {
    return domainLogo ? logo : null;
  }

  return domainLogo ? (
    <Link
      component={RouterLink}
      href={isLoginOrRegister ? `https://${getDomain(domainInfoLocal?.domain)}/` : '/dashboard'}
      sx={{ display: 'contents' }}
    >
      {logo}
    </Link>
  ) : 
    <Box sx={{ maxWidth: 65, minHeight: 65, cursor: 'pointer', ...sx }}
    />;
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;