import { useEffect, useMemo, useState } from 'react';
// @mui
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Tooltip } from '@mui/material';
// auth
import { useAuthContext } from 'src/auth/hooks';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import { SplashScreen } from 'src/components/loading-screen';
import AttributionModal from 'src/components/attribution-modal';

import { getAPIUrl } from 'src/config';
import { useDomainContext } from 'src/context/domain-context';
import { useGetFavicon } from 'src/hooks/use-get-fav';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, image, title }) {
  const { getCompanyInfo } = useAuthContext();
  const { domainInfo } = useDomainContext();
  const [modalOpen, setModalOpen] = useState(false);

  useGetFavicon();

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const themeSettings = useMemo(
    () =>
      domainInfo?.internal_brand?.theme_setting
        ? JSON.parse(domainInfo?.internal_brand?.theme_setting)
        : {},
    [domainInfo]
  );

  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, lg: 8 },
        pt: { md: 0, xs: 15 },
        pb: { md: 0, xs: 8 },
        justifyContent: 'center',
        height: { md: '100vh', xs: 'auto' },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      pb={themeSettings?.welcome_full_screen ? 0 : 5}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay.jpg',
        }),
        display: { md: 'none', lg: 'block' },
        position: 'relative',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          maxWidth: 'none',
          textAlign: 'center',
          position: themeSettings?.welcome_full_screen ? 'absolute' : 'initial',
          top: themeSettings?.welcome_full_screen ? '55px' : '',
          left: 0,
          right: 0,
          mt: themeSettings?.welcome_full_screen ? 0 : 6
        }}
      >
        {(themeSettings?.show_welcome_message || themeSettings?.show_welcome_message === undefined) ? title ||
          (domainInfo?.internal_brand?.company_name
            ? `Welcome to ${domainInfo?.internal_brand?.company_name}`
            : 'Hi, Welcome back'): ""}
      </Typography>

      {domainInfo?.internal_brand?.welcome_image && themeSettings?.welcome_full_screen ? (
        <Box
          component="img"
          alt="auth"
          src={
            domainInfo?.internal_brand?.welcome_image
              ? domainInfo?.internal_brand?.welcome_image?.includes('http')
                ? domainInfo?.internal_brand?.welcome_image
                : `${getAPIUrl()}/${domainInfo?.internal_brand?.welcome_image}`
              : ''
          }
          sx={{
            maxWidth: themeSettings?.welcome_full_screen ? '100%' : 720,
            width: themeSettings?.welcome_full_screen ? '100%' : 'auto',
            height: themeSettings?.welcome_full_screen ? '100vh' : 'auto',
            maxHeight: themeSettings?.welcome_full_screen ? '100vh' : '500px',
            objectFit: themeSettings?.welcome_full_screen ? 'cover' : 'contain',
            flexGrow: 1,
          }}
        />
      ) : domainInfo?.internal_brand?.welcome_image && !themeSettings?.welcome_full_screen ? (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Box
            component="img"
            alt="auth"
            src={
              domainInfo?.internal_brand?.welcome_image
                ? domainInfo?.internal_brand?.welcome_image?.includes('http')
                  ? domainInfo?.internal_brand?.welcome_image
                  : `${getAPIUrl()}/${domainInfo?.internal_brand?.welcome_image}`
                : ''
            }
            sx={{
              maxWidth: themeSettings?.welcome_full_screen ? '100%' : 720,
              width: themeSettings?.welcome_full_screen ? '100%' : 'auto',
              height: themeSettings?.welcome_full_screen ? '100vh' : 'auto',
              maxHeight: themeSettings?.welcome_full_screen ? '100vh' : '500px',
              objectFit: themeSettings?.welcome_full_screen ? 'cover' : 'contain',
              flexGrow: 1,
            }}
          />
        </Stack>
      ) : null}

      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          bottom: 24,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'text.secondary',
          zIndex: 9,
        }}
      >
        Product by{' '}
        <Tooltip
          title="This product was developed by Quantum Edge LTD. Quantum Edge LTD is not affiliated with, nor responsible for, the conduct, performance, or practices of any third-party brokers using this software."
          arrow
          enterDelay={200}
          leaveDelay={200}
          sx={{ maxWidth: 300 }}
        >
          <Link
            component="button"
            variant="caption"
            onClick={() => setModalOpen(true)}
            sx={{ 
              textDecoration: 'none',
              color: 'primary.main',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Quantum Edge LTD
          </Link>
        </Tooltip>
      </Typography>

      <AttributionModal 
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Stack>
  );

  return domainInfo?.company_id ? (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        flexDirection: {
          md: themeSettings?.welcome_full_screen ? 'column' : 'row',
          lg: 'row',
          xl: 'row',
        },
      }}
    >
      {themeSettings?.show_logo === undefined || themeSettings?.show_logo ? renderLogo : null}

      {upMd && renderSection}

      {renderContent}
    </Stack>
  ) : (
    <SplashScreen />
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
