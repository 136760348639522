import { createContext, useContext, useMemo } from 'react';
import useDomainInfo from '../hooks/use-domain';

const DomainContext = createContext(null);

export function DomainProvider({ children }) {
  const { domainData: domainInfo, isLoading } = useDomainInfo();

  const value = useMemo(() => ({
    domainInfo,
    isLoading
  }), [domainInfo, isLoading]);

  return (
    <DomainContext.Provider value={value}>
      {children}
    </DomainContext.Provider>
  );
}

export function useDomainContext() {
  const context = useContext(DomainContext);
  
  if (!context) {
    throw new Error('useDomainContext must be used within a DomainProvider');
  }
  
  return context;
} 