import { useEffect } from 'react'

import { getAPIUrl } from '../config';
import { useDomainContext } from '../context/domain-context';

export const useGetFavicon = () => {
  const { domainInfo } = useDomainContext();

  useEffect(() => {
    if (domainInfo) {
      document.title = `${domainInfo?.internal_brand?.company_name}`;

      const fav = document.querySelectorAll('.custom_fav');
      fav.forEach((currentFav) => {
        currentFav.setAttribute('href', `${domainInfo?.internal_brand?.dashboard_favicon ?
          domainInfo?.internal_brand?.trader_favicon?.includes('http') ? domainInfo?.internal_brand?.dashboard_favicon : `${getAPIUrl()}/${domainInfo?.internal_brand?.dashboard_favicon}` :
          ''}`);
      });
    }
  }, [domainInfo]);
}

