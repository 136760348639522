import { lazy, useEffect } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { paths } from '../paths';
import { useDomainContext } from 'src/context/domain-context';
import { useSettingsContext } from 'src/components/settings';

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export default function Router() {
  const settings = useSettingsContext();
  const { domainInfo } = useDomainContext();

  useEffect(() => {
    if(domainInfo?.colorTheme) {
      settings.onUpdate('themeMode', domainInfo?.colorTheme)
    }
    if(domainInfo?.colorPreset) {
      settings.onUpdate('themeColorPresets', domainInfo?.colorPreset)
    }
  }, [domainInfo])

  return useRoutes([
    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: <Outlet />,
      children: [{ element: <Navigate to={paths.login} />, index: true }],
    },

    // SET 404 PAGE
    {
      path: '404',
      element: <Page404 />
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
